import { Button, Typography } from '@mui/material'
import { devices } from 'hooks/useMediaQueries'
import styled from 'styled-components'
import { Div } from 'styled/utils'

type MainCTAProps = {
  animationDelay?: number
  children: React.ReactNode
  onClick: () => void
}
export function MainCTA({
  animationDelay = 0.6,
  children,
  onClick
}: MainCTAProps) {
  return (
    <ButtonContainer $slideUp={{ delay: animationDelay }}>
      <ContactButton variant="contained" onClick={onClick}>
        <Typography variant="mainCTA">{children}</Typography>
      </ContactButton>
    </ButtonContainer>
  )
}

export const ContactButton = styled(Button)`
  width: 296px;
  height: 64px;

  @media ${devices.tablet} {
    width: 264px;
  }

  @media ${devices.mobile} {
    width: 100%;
  }
`
export const ButtonContainer = styled(Div)`
  opacity: 0;
`
