import Rollbar from 'rollbar'
import { wrapPage } from './root-wrapper'

// Fonts
import './src/styles/fonts.css'

const rollbar = new Rollbar({
  accessToken: process.env.GATSBY_ROLLBAR_ACCESS_TOKEN,
  captureUncaught: true,
  captureUnhandledRejections: true,
  environment: 'production',
  codeVersion: process.env.GATSBY_CODEBUILD_RESOLVED_SOURCE_VERSION,
  payload: {
    environment: 'production',
    client: {
      javascript: {
        source_map_enabled: true,
        code_version: process.env.GATSBY_CODEBUILD_RESOLVED_SOURCE_VERSION,
        guess_uncaught_frames: true
      }
    }
  }
})

export const onClientEntry = () => {
  window.rollbar = rollbar
}

export const wrapPageElement = wrapPage
