import React from 'react'
import styled, { css } from 'styled-components'
import { appCss } from './theme'

export const themeProps = css`
  ${(props: any) =>
    Object.keys(props)
      .map((p) => props?.theme?.css && props.theme.css[p])
      .filter((c) => c)};
`
export type CSSProps = {
  [K in keyof typeof appCss]?: any
}

// may be used to avoid adding styled components prop types
// keeps component prop references to css snippets in theme
export type CSSAnyProps = CSSProps | { [key: string]: any }

export const Div = styled.div<CSSProps>`
  ${themeProps};
`

export const A = styled.a<CSSProps>`
  ${themeProps};
`
export const Img = styled.img<CSSProps>`
  ${themeProps};
`

export const Span = styled.span<CSSProps>`
  ${themeProps};
`

export const H1 = styled.h1<CSSProps>`
  ${themeProps};
`

export const H2 = styled.h2<CSSProps>`
  ${themeProps};
`

export const H3 = styled.h3<CSSProps>`
  ${themeProps};
`

export const H4 = styled.h4<CSSProps>`
  ${themeProps};
`

export const P = styled.p<CSSProps>`
  ${themeProps};
`

export type DivProps = React.HTMLAttributes<HTMLDivElement>
export type DivCSSProps = DivProps & CSSProps
