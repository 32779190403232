import { MainCTA } from './MainCTA'
import { useContactDialogContext } from 'context/ContactDialogContextProvider'

export const LetsWorkTogetherButton = ({
  animationDelay = 0.6
}: LetsWorkTogetherButtonProps) => {
  const { setDialogOpen } = useContactDialogContext()

  return (
    <MainCTA
      onClick={() => setDialogOpen(true)}
      animationDelay={animationDelay}
    >
      Let's work together
    </MainCTA>
  )
}
type LetsWorkTogetherButtonProps = {
  animationDelay?: number
}
